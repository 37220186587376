<template>
  <v-card  class="pa-2"
        outlined
        tile>
       <h2 class="text-center "> Выберете тип конструктора </h2> 
       <h3  >По будням с 12:00 - 16:00 </h3> 

      <v-divider></v-divider>

      <v-divider></v-divider>
         

        <v-row>
        <v-col class="d-flex justify-center mb-6">
        <a> 
        <v-card min-width="230" max-width="300px" to='/lunch'>
            <v-card-title>
                Бизнес-ланч <v-spacer></v-spacer> 
            </v-card-title>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/soup.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/garnir.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/skewer.svg">
                            </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="40">
                            <v-img src="../../assets/tea.svg">
                            </v-img>
                    </v-list-item-avatar>
        </v-card>
        </a>
        </v-col>
        </v-row>

  </v-card>
   </template>
   
   
   <script>   
   export default {
       name: "calc-type",
       props: {
         cart_item_data: {
           type: Object,
           default() {
             return {}
           }
         }
       },
       data() {
         return {}
       },
       filters: {
       },
       computed: {
         
       },
       methods: {
       }
     }
   </script>
   <style>

   </style>
   
   