<template>
  <v-card
      class="mx-auto"
  >
    <h2 class="text-center pt-5">ДОСТАВКА И ОПЛАТА</h2>

    <v-card-subtitle class="pb-0"></v-card-subtitle>

    <v-card-text class="text--primary">
      <div class="wrap-content">

        <p style="text-align: justify;">
          <span style="font-size:14px;"> <strong>300 руб в дневное время </strong> </span></p>
          <p style="text-align: justify;"> <span style="font-size:14px;"> <strong> 350 в ночное </strong></span></p>
 

        <p style="text-align: justify;">
          <span style="font-size:14px;">Доставки от 20 км осуществляется бесплатно при сумме заказа от 5000 рублей. Если заказ составляет менее 5000 рублей-стоимость доставки 700 рублей</span>
        </p>

        <iframe src="https://www.google.com/maps/d/embed?mid=1xG4EUMWYESEqMs34hJCpwZIhg52cM3CC" width="70%"
                height="480"></iframe>


        <p style="text-align: justify;">
          <span style="font-size:14px;"><strong>Способы оплаты</strong></span></p>


        <p style="text-align: justify;"><span style="font-size:14px;">1. Наличный расчет</span></p>
        <p>
          Если товар доставляется курьером, то оплата осуществляется наличными курьеру в руки. При
          получении товара обязательно проверьте комплектацию товара, наличие гарантийного талона и
          чека.
        </p>

        <p style="text-align: justify;"><span
            style="font-size:14px;">2.	Банковской картой через мобильный терминал</span></p>

        <p style="text-align: justify;"><span style="font-size: 14px;">3.	Банковской картой</span></p>

        <p>
          Для выбора оплаты товара с помощью банковской карты на соответствующей странице
          необходимо нажать кнопку Оплата заказа банковской картой. Оплата происходит через ПАО
          СБЕРБАНК с использованием банковских карт следующих платёжных систем:
        </p>

        <v-container fluid fill-height>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6" md="4">
              <div class="text-center">
                <p>
                  МИР
                </p>
                <v-img
                    max-height="50"
                    max-width="55"
                    src="../assets/logo-mir.png"
                ></v-img>
              </div>
              <div class="text-center">
                <p>
                  VIS International
                </p>
                <v-img
                    max-height="50"
                    max-width="55"
                    src="../assets/logo-visa.png"
                ></v-img>
              </div>
              <div class="text-center">
                <p>
                  Mastercard Worldwide
                </p>
                <v-img
                    max-height="50"
                    max-width="55"
                    src="../assets/logo-mastercard.png"
                ></v-img>
              </div>
              <div class="text-center">
                <p>
                  JCB
                </p>
                <v-img
                    max-height="50"
                    max-width="55"
                    src="../assets/jcb.jpg"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <p>
          Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз
          ПАО СБЕРБАНК, Соединение с платёжным шлюзом и передача информации осуществляется в
          защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк
          поддерживает технологию безопасного проведения интернет-платежей Verified By Visa,
          MasterCard SecureCode, MIR Accept, j-Secure для проведения платежа также может
          потребоваться ввод специального пароля,

          Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой
          ‘персональной информации обеспечивается ПАО СБЕРБАНК, Введённая информация не будет
          предоставлена третьим лицам за исключением случаев, предусмотренных законодательством
          РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с
          требованиями платёжных систем МИР, Visa int, MasterCard Europe Sprl, JCB:
        </p>
        <div>
          <h3>
            Возврат товара:
          </h3>
            <p>
              Возврат переведённых средств, в случае заранее отмененного заказа или назначенного времени самовывоза производится на ваш банковский счёт в течении 5 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту)
            </p>
        </div>
      </div>
      <p>
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
}
</script>
<style>
.text-center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>