<template>
  <v-card>
      <h2> Акции </h2>
      <v-card-text class="black--text amber lighten-1--text mr-6"><h3> Хот-дог, шаурма в багете-фирменный напиток в подарок </h3> (Тан, томатный сок 0,5 л) </v-card-text>
      <br/>
      <v-card-text class="black--text amber lighten-1--text mr-6"><h3> 0,5 морса в подарок </h3> Сеты все кроме пивного </v-card-text>
      <br/>
      <!-- <v-card-text class="black--text amber lighten-1--text mr-6"><h3> Сырное ассорти комплимент в подарок. </h3> Чек от 5000 руб.(доставка, самовывоз) </v-card-text>
      <br/>
      <v-card-text class="black--text amber lighten-1--text mr-6"><h3> Сырное ассорти комплимент в подарок. </h3> Чек от 3500 руб. (Либкнехта в зале)- </v-card-text> -->
      <!-- <br/> -->
      <v-card-text class="black--text amber lighten-1--text mr-6"><h3> При покупке пивного сета и 0,5 Очаг фирменное 2-й 0,5 в подарок. (Только Либкнехта)</h3> </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>
