<template>
    <v-footer
      color="#474428"
      dark
      padless
    >
     
     <v-card
      class="flex text-center"
      flat
      tile
      color = "#15151d"
    >
      <v-card-text >
        <v-spacer></v-spacer>

       
        <!-- <v-btn
          class="mx-4"
         
          icon
        >
          <a href="https://www.instagram.com/ochag_55/" target="_blank"><img src="../../assets/inst-icon.svg" width="30" height="30" /></a>
        </v-btn> -->

         <v-btn
          class="mx-4"
          amber
          icon
        >
          <a href="https://vk.com/ochag55" target="_blank"><img src="../../assets/vk-icon2.svg" width="30" height="30" /></a>
        </v-btn>
        
      </v-card-text>
 <router-link to="/confidance" >Политика конфиденциальности </router-link>
 <br>
 <router-link to="/userpolitic" > Пользовательское соглашение</router-link>
      <v-card-text class="py-2 white--text text-center">
        <p class="footerText">Адреса: г. Омск, ул. Волочаевская 19 к4
      , ул. Карла Либкнехта, 6</p>
      </v-card-text>
    </v-card>
  
    </v-footer>
</template>
<script>


export default {
    name: "v-footer-page",

    data(){
        return {
        }
    },
}
</script>